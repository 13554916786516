<script setup lang="ts"></script>

<template>
  <div class="app-public-wrapper">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.app-public-wrapper {
  padding-top: 4rem;
}
</style>
